<template>
  <div class="wrapper">
    <svg id="left" fill="#000000" width="40px" height="40px" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <path d="m5.82 8 5.66-5.56-.87-.89L4.9 7.09a1.18 1.18 0 0 0-.39.91 1.13 1.13 0 0 0 .39.85l5.7 5.7.89-.88z" />
    </svg>
    <div class="carousel">
      <template v-for="(item,key) in slider.slice().reverse()" :key="key">
        <img v-lazy="{src:item.img,error: require('@/assets/sliderLazyErr.png'),loading:  require('@//assets/sliderLazy.png')}" src="https://source.unsplash.com/random/1300x600" alt="img" draggable="false"/>
      </template>
    </div>
    <svg id=" right" fill="#000000" width="40px" height="40px" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <path d="m10.18 8.05-5.66 5.56.87.89 5.71-5.59a1.18 1.18 0 0 0 .39-.86 1.13 1.13 0 0 0-.39-.85L5.4 1.5l-.89.88z" />
    </svg>
  </div>
</template>
<script>
import axiosCustom from "@/axiosCustom";

export default {
  name: 'App',
  data() {
    return {
      slider:[]
    }
  },
  created() {

    axiosCustom
        .get('slider')
        .then(response => {
          this.slider = response.data
        })
        .catch(error => {
          console.log(error);
        })
  },
  mounted() {
    const carousel = document.querySelector(".carousel"),
        arrowIcons = document.querySelectorAll(".wrapper svg");

    let isDragStart = false,
        isDragging = false,
        prevPageX,
        prevScrollLeft,
        positionDiff;

    const showHideIcons = () => {
      let scrollWidth = carousel.scrollWidth - carousel.clientWidth;
      arrowIcons[0].style.display = carousel.scrollLeft === 0 ? "none" : "block";
      arrowIcons[1].style.display =
          carousel.scrollLeft - scrollWidth > -1 ? "none" : "block";
    };

    arrowIcons.forEach((icon) => {
      let firstImgWidth = document.body.clientWidth;
      icon.addEventListener("click", () => {
        carousel.scrollLeft += icon.id === "left" ? -firstImgWidth : firstImgWidth;
        setTimeout(() => showHideIcons(), 60);
      });
    });

    const autoSlide = () => {
      if (
          carousel.scrollLeft - (carousel.scrollWidth - carousel.clientWidth) > -1 ||
          carousel.scrollLeft <= 0
      )
        return;

      positionDiff = Math.abs(positionDiff);
      let firstImgWidth = document.body.clientWidth;
      let valDifference = firstImgWidth - positionDiff;

      if (carousel.scrollLeft > prevScrollLeft) {
        return (carousel.scrollLeft +=
            positionDiff > firstImgWidth / 4 ? valDifference : -positionDiff);
      }
      carousel.scrollLeft -=
          positionDiff > firstImgWidth / 4 ? valDifference : -positionDiff;
    };

    const dragStart = (e) => {
      isDragStart = true;
      prevPageX = e.pageX || e.touches[0].pageX;
      prevScrollLeft = carousel.scrollLeft;
    };

    const dragging = (e) => {
      if (!isDragStart) return;
      isDragging = true;
      carousel.classList.add("dragging");
      positionDiff = (e.pageX || e.touches[0].pageX) - prevPageX;
      carousel.scrollLeft = prevScrollLeft - positionDiff;
      showHideIcons();
    };

    const dragStop = () => {
      isDragStart = false;
      carousel.classList.remove("dragging");

      if (!isDragging) return;
      isDragging = false;
      autoSlide();
    };

    carousel.addEventListener("mousedown", dragStart);
    carousel.addEventListener("touchstart", dragStart);

    document.addEventListener("mousemove", dragging);
    carousel.addEventListener("touchmove", dragging);

    document.addEventListener("mouseup", dragStop);
    document.addEventListener("mouseleave", dragStop);
    carousel.addEventListener("touchend", dragStop);

  }
}
</script>
<style scoped>
.wrapper{
  display: flex;
  width: 100vw;
  height: 100vh;
  position: relative;
  box-shadow: 0 10px 20px rgba(0,0,0,0.1);
}
.wrapper svg{
  top: 50%;
  padding:5px;
  color: #343F4F;
  cursor: pointer;
  position: absolute;
  background: #fff;
  border-radius: 50%;
  transform: translateY(-50%);
  transition: transform 0.1s linear;
}
.wrapper svg:active{
  transform: translateY(-50%) scale(0.9);
}
.wrapper svg:hover{
  background: #f2f2f2;
}
.wrapper svg:first-child{
  left: 22px;
  display: none;
  z-index: 19;
}
.wrapper svg:last-child{
  right: 22px;
}
.wrapper .carousel{
  font-size: 0px;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  scroll-behavior: smooth;
}
.carousel.dragging{
  cursor: grab;
  scroll-behavior: auto;
}
.carousel.dragging img{
  pointer-events: none;
}
.carousel img{
  height: 100vh;
  object-fit: cover;
  user-select: none;
  /*width: calc(100% / 3);*/
  width: 100%;
}
.carousel img:first-child{
  margin-left: 0px;
}

</style>
<style>
.carousel__prev{
  left: 50px;
  top: 50%;
  background-color: transparent!important;
  width: 50px;
  height: 50px;
  border: 1px solid white;
  border-radius: 0;
}
.carousel__prev svg,
.carousel__next svg{
  width: 50px;
  height: 50px;
}

.carousel__next {
  right: 50px;
  top: 50%;
  background-color: transparent!important;
  width: 50px;
  height: 50px;
  border: 1px solid white;
  border-radius: 0;
}
</style>
