
import {createRouter, createWebHistory} from 'vue-router'
import Home from '../components/views/Home.vue'
const routes = [
  {
    path: '/',
    name: 'Anasayfa',
    component: Home
  },
  {
    path: '/anasayfa',
    name: 'Anasayfa ',
    component: Home
  },
  {
    path: '/kurumsal',
    name: 'Kurumsal ',
    component: () => import('../components/views/About.vue'),
  },
  {
    path: '/iletisim',
    name: 'İletişim',
    component: () => import('../components/views/Contact'),
  },

  {
    path: '/:catName',
    name: 'Kategori ',
    component: () => import('../components/views/Cat.vue'),
    meta: {
      footer: 'none',
    }
  },
  {
    path: '/test',
    name: 'test ',
    component: () => import('../components/Common/Test.vue'),
    meta: {
      footer: 'none',
    }
  },
  {
    path: '/yonet',
    name: 'Yönet',
    component: () => import('../components/views/Admin.vue'),
    meta: {
      footer: 'none',
      header: 'none',
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
export default router

