import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import Axios from "axios"
import VueLazyload from '@jambonn/vue-lazyload'
import VueKinesis from "vue-kinesis";
// /import anime from "animejs/lib/anime.es.js";
import './registerServiceWorker'

const loadimage = require('../public/loader.gif')
const errorimage = require('../public/error.gif')
createApp(App).use(router,Axios,VueKinesis).use(VueLazyload, {
    preLoad: 1.3,
    error: errorimage,
    loading: loadimage,
    attempt: 1
}).mount('#app')












