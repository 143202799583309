<template>
  <footer>
    <div class="bd-footer__section theme-bg-2 pt-120">
      <div class="container">
        <div class="bd-footer__main pb-50">
          <div class="row">
            <div class="col-lg-12">
              <div class="d-flex flex-column  flex-md-row  w-100 justify-content-md-between gap-5 align-items-center">
                <div class="footerLogo">
                  <router-link to="/anasayfa">
                    <img :src="require('@/assets/img/logo/logo.svg')" alt="logo">
                  </router-link>
                </div>

                <div class="footerMenu d-flex justify-content-center justify-content-md-end">
                  <ul>
                    <li v-for="item in cats.slice().reverse()">
                      <router-link :to="'/'+item.seo">
                        {{item.title}}
                      </router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex flex-column  flex-md-row-reverse justify-content-md-between align-items-center bd-border-top ">

          <div class="bd-footer-widget__social pt-20 pb-10">
            <span v-if="social.facebook"><a :href="social.facebook" title="facebook" aria-label="facebook"><i class="fab fa-facebook-f"></i></a></span>
            <span v-if="social.twitter"><a :href="social.twitter" title="twitter" aria-label="twitter"><i class="fab fa-twitter"></i></a></span>
            <span v-if="social.youtube"><a :href="social.youtube" title="youtube" aria-label="youtube"><i class="fab fa-youtube"></i></a></span>
            <span v-if="social.linkedin"><a :href="social.linkedin" title="linkedin" aria-label="linkedin"><i class="fab fa-linkedin"></i></a></span>
            <span v-if="social.instagram"><a :href="social.instagram" title="instagram" aria-label="instagram"><i class="fab fa-instagram"></i></a></span>
          </div>
          <div class="bd-footer__copyright pt-20 pb-10">
            <span>Tüm Hakları Saklıdır© 2022 <a href="//gifadwork.com" title="gif" aria-label="gif">Gif</a> </span>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import axiosCustom from "../../axiosCustom";
export default {
  data() {
    return {
      social: [],
      cats: []
    }
  },
  created() {
    axiosCustom
        .get('social')
        .then(response => {
          this.social = response.data[0]
        })
        .catch(error => {
          console.log(error);
        })
    axiosCustom
        .get('cats')
        .then(response => {
          this.cats = response.data
        })
        .catch(error => {
          console.log(error);
        })
    },
}
</script>
