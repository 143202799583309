<template>

  <header>
    <div class="bd-header__section bd-header__transparent">
      <div class="bd-header__main" id="header-sticky">
        <div class="container">
          <div class="row align-items-center position-relative">
            <div class="col-lg-2 col-md-6 col-6">
              <div class="logo">
                <router-link to="/anasayfa">
                  <img :src="require('@/assets/img/logo/logo.svg')" alt="logo">
                </router-link>
              </div>
            </div>
            <div class="col-lg-10 col-md-6 col-6">
              <div class="d-flex justify-content-end">
                <div :class="['main-menu', (mobileActive == true ? 'active' : '')]">

                  <nav @click="mobileActive = !mobileActive" id="mobile-menu"
                       :class="[(this.$route.href === '/anasayfa' ? 'homeHeader' : ''),(this.$route.href === '/' ? 'homeHeader' : '')]">
                    <ul>
                      <li>
                        <router-link to="/">
                          Anasayfa
                        </router-link>
                      </li>
                      <li>
                        <router-link to="/kurumsal">
                          Kurumsal
                        </router-link>
                      </li>

                      <li class="has-dropdown">
                        <router-link to="#">Ürünler</router-link>
                        <ul class="submenu">
                          <li v-for="item in cats.slice().reverse()">
                            <router-link :to="'/'+item.seo">
                              {{item.title}}
                            </router-link>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <router-link to="/iletisim">
                          İletisim
                        </router-link>
                      </li>
                    </ul>
                  </nav>
                  <div class="middle">
                    <a @click="mobileActive = !mobileActive" href="#" title="menu" aria-label="menu"
                       :class="['menu two', (mobileActive == true ? 'active' : '')]"><span></span></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>
<script>
import axiosCustom from "../../axiosCustom";

axiosCustom
export default {
  data() {
    return {
      cats: [],
      mobileActive:false
    }
  },
  created() {
    axiosCustom
        .get('cats')
        .then(response => {
          this.cats = response.data
        })
        .catch(error => {
          console.log(error);
        })
  },
}
</script>
<style scoped>
.middle {
  width: 70px;
  height: 40px;
  position: absolute;
  top: 60%;
  right: 0;
  margin: -20px 0 0 -35px;
  display: none;
}

@media screen and (max-width: 991px) {
  .main-menu #mobile-menu {
    display: none;
    position: relative;
    top: 0;
    left: 0;
  }
  .main-menu ul li .submenu li a::before{
    display: none;
  }
  .middle {
    display: block;
  }

  .main-menu #mobile-menu ul:not(.sub) {
    display: flex;
    position: fixed;
    width: 0vw;
    background-color: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(30px);
    -webkit-backdrop-filter: blur(30px);
    top: 0;
    left: 0;
    height: 100vh;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-top: 50px;
    padding-left: 50px;
    transition: width 1s;
  }

  .main-menu ul:not(.sub) li a {
    color: var(--bd-common-white);
  }

  .main-menu.active #mobile-menu {
    display: block;
    position: absolute;
    width: 100vw;
    height: 100vh;
  }

  .main-menu.active #mobile-menu ul:not(.sub) {
    width: 70vw;
  }

  a.menu {
    float: left;
    width: 30px;
    height: 23px;
    overflow: hidden;
    transition: 0.3s;
  }

  a.menu span {
    float: left;
    width: 30px;
    height: 3px;
    background-color: var(--bd-common-black);
    position: relative;
    top: 10px;
    transition: 0.3s;
  }

  a.menu span:before {
    content: "";
    width: 30px;
    height: 3px;
    background-color: var(--bd-common-black);
    position: absolute;
    top: -8px;
    left: 0;
  }

  a.menu span:after {
    content: "";
    width: 30px;
    height: 3px;
    background-color: var(--bd-common-black);
    position: absolute;
    bottom: -8px;
    left: 0;
  }

  a.menu.active {
    transform: rotate(360deg);
  }

  a.menu.active span {
    background-color: transparent;
  }

  a.menu.active span:before {
    top: 0;
    transform: rotate(-45deg);
  }

  a.menu.active span:after {
    bottom: 0;
    transform: rotate(45deg);
  }
}
</style>
