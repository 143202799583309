<template>
  <div id="home">
    <div class="wrapper" style="height: 100vh">
      <svg id="left" fill="#000000" width="40px" height="40px" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
        <path d="m5.82 8 5.66-5.56-.87-.89L4.9 7.09a1.18 1.18 0 0 0-.39.91 1.13 1.13 0 0 0 .39.85l5.7 5.7.89-.88z" />
      </svg>
      <div class="carousel">
        <template v-for="(item,key) in slider.slice().reverse()" :key="key">
          <img class="forDesktop" v-lazy="{src:item.img,error: require('@/assets/sliderLazyErr.png'),loading:  require('@//assets/sliderLazy.png')}" alt="img" draggable="false"/>
          <img class="forMobile" v-lazy="{src:item.img2,error: require('@/assets/sliderLazyErr.png'),loading:  require('@//assets/sliderLazy.png')}" alt="img" draggable="false"/>
        </template>
      </div>
      <svg id=" right" fill="#000000" width="40px" height="40px" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
        <path d="m10.18 8.05-5.66 5.56.87.89 5.71-5.59a1.18 1.18 0 0 0 .39-.86 1.13 1.13 0 0 0-.39-.85L5.4 1.5l-.89.88z" />
      </svg>
    </div>
    <kinesis-container event="scroll">
      <kinesis-element style="position:absolute;z-index: 3;max-width: 150px;left: 50px" :strength="120"  type="rotate" transformOrigin="left"
                       tag="img" alt="img" :src="require('@/assets/img/kinesisImg/caramel.png')"/>
      <kinesis-element style="position:absolute;right:50px;top:250px;z-index: 3;max-width: 60px" type="scale"
                       :strength="50" tag="img" alt="img" :src="require('@/assets/img/kinesisImg/sweet.png')"/>
      <div class="bd-services__area  pt-120 pb-90  parallaxConatin"
           v-lazy:background-image="{src: require('@/assets/img/parallax/homeAbout.png')}">
        <div class="row justify-content-center">
          <div class="col-12">
            <div class="bd-section__title-wrapper text-center mb-90 wow fadeInUp">
              <span class="bd-section__subtitle mb-5">1958'den beri</span>
              <h3 class="bd-section__title bd-title-border">{{about.title}}</h3>
            </div>
          </div>
          <div class="col-6 lp" v-html="about.desc">
          </div>
        </div>
        <kinesis-element
            tag="img" :strength="-4000"
            alt="img"
            type="translate" axis="x"
            style="max-height: 100px"
            :src="require('@/assets/img/kinesisImg/horse.png')"/>
      </div>
      <div class="bd-about__section  pt-120 pb-90 theme-bg"
           v-lazy:background-image="{src: require('@/assets/img/parallax/patternOne.png')}"
           style="background-position: bottom center">
        <div class="container-fluid">
          <div class="row justify-content-center">
            <div class="col-12">
              <div class="bd-section__title-wrapper text-center mb-90 wow fadeInUp">
                <span class="bd-section__subtitle mb-5">1958'den beri</span>
                <h3 class="bd-section__title bd-title-border">Leziz Ürünlerimiz</h3>
              </div>
            </div>
          </div>
          <div class="catContent">
            <div class="catRow">
              <div class="cat  wow fadeInUp" v-for="item in cats.slice().reverse()">
                <router-link :to="'/'+item.seo">
                  <img v-lazy="item.img" class="img-fluid" :alt="item.title">
                  <span class="title">{{ item.title }}</span>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bd-team-3__section  pt-120 pb-20">
        <kinesis-element
            tag="img" :strength="7000"
            alt="img"
            type="translate" axis="x"
            style="max-height: 100px;position: absolute"
            :src="require('@/assets/img/kinesisImg/horse2.png')"/>
        <div class="container-fluid">
          <div class="row">
            <div class="col-12">
              <div class="bd-section__title-wrapper text-center mb-90 wow fadeInUp">
                <h3 class="bd-section__title bd-title-border mb-25">Bizi Takip Et</h3>
              </div>
            </div>
          </div>
          <div class="row" v-lazy-container="{ selector: 'img' }">
            <div class="col-6 col-md-4 col-lg-3" v-for="item in social.instaimg">
              <a :href="social.instagram" target="_blank"
                 class="bd-team-3__member text-center mb-30">
                <div class="bd-team-3__img p-relative mb-30 fix w-img">
                  <img :data-src="item" alt="konakcandy">
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <app-footer/>
    </kinesis-container>

  </div>
</template>
<script>
import slider from "@/components/Common/slider";
import appFooter from "@/components/Common/Footer";
import {KinesisContainer, KinesisElement} from 'vue-kinesis'
import axiosCustom from "@/axiosCustom";

export default {
  name: 'App',
  components: {
    appFooter,
    KinesisContainer,
    KinesisElement,
    slider
  },
  data() {
    return {
      about:[],
      social:[],
      cats:[],
      slider:[]
    }
  },
  created() {
    document.title = 'Anasayfa'
    axiosCustom
        .get('slider')
        .then(response => {
          this.slider = response.data
        })
        .catch(error => {
          console.log(error);
        })
    axiosCustom
        .get('about')
        .then(response => {
          this.about = response.data[0]
        })
        .catch(error => {
          console.log(error);
        })
    axiosCustom
        .get('cats')
        .then(response => {
          this.cats = response.data
        })
        .catch(error => {
          console.log(error);
        })
    axiosCustom
        .get('social')
        .then(response => {
          this.social = response.data[0]
        })
        .catch(error => {
          console.log(error);
        })
    },

  mounted() {
    const carousel = document.querySelector(".carousel"),
        arrowIcons = document.querySelectorAll(".wrapper svg");

    let isDragStart = false,
        isDragging = false,
        prevPageX,
        prevScrollLeft,
        positionDiff;

    const showHideIcons = () => {
      let scrollWidth = carousel.scrollWidth - carousel.clientWidth;
      arrowIcons[0].style.display = carousel.scrollLeft === 0 ? "none" : "block";
      arrowIcons[1].style.display =
          carousel.scrollLeft - scrollWidth > -1 ? "none" : "block";
    };

    arrowIcons.forEach((icon) => {
      // let firstImgWidth = document.body.clientWidth;
      let firstImgWidth = screen.width;
      icon.addEventListener("click", () => {
        carousel.scrollLeft += icon.id === "left" ? -firstImgWidth : firstImgWidth;
        setTimeout(() => showHideIcons(), 60);
      });
    });

    const autoSlide = () => {
      if (
          carousel.scrollLeft - (carousel.scrollWidth - carousel.clientWidth) > -1 ||
          carousel.scrollLeft <= 0
      )
        return;

      positionDiff = Math.abs(positionDiff);
      // let firstImgWidth = document.body.clientWidth;
      let firstImgWidth = screen.width;
      let valDifference = firstImgWidth - positionDiff;

      if (carousel.scrollLeft > prevScrollLeft) {
        return (carousel.scrollLeft +=
            positionDiff > firstImgWidth / 1000 ? valDifference : -positionDiff);
      }
      carousel.scrollLeft -=
          positionDiff > firstImgWidth / 1000 ? valDifference : -positionDiff;
    };

    const dragStart = (e) => {
      isDragStart = true;
      prevPageX = e.pageX || e.touches[0].pageX;
      prevScrollLeft = carousel.scrollLeft;
    };

    const dragging = (e) => {
      if (!isDragStart) return;
      isDragging = true;
      carousel.classList.add("dragging");
      positionDiff = (e.pageX || e.touches[0].pageX) - prevPageX;
      carousel.scrollLeft = prevScrollLeft - positionDiff;
      showHideIcons();
    };

    const dragStop = () => {
      isDragStart = false;
      carousel.classList.remove("dragging");

      if (!isDragging) return;
      isDragging = false;
      autoSlide();
    };

    carousel.addEventListener("mousedown", dragStart);
    carousel.addEventListener("touchstart", dragStart);

    document.addEventListener("mousemove", dragging);
    carousel.addEventListener("touchmove", dragging);

    document.addEventListener("mouseup", dragStop);
    document.addEventListener("mouseleave", dragStop);
    carousel.addEventListener("touchend", dragStop);

  },

  beforeRouteLeave(to, from, next) {
    next();
    window.scrollTo(0, 0);
  },
  beforeRouteEnter(to, from, next) {
    next();
    window.scrollTo(0, 0);
  }
}
</script>
