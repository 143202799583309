<template>
    <app-header v-if="!$route.meta.header" ></app-header>
    <router-view v-slot="{ Component }">
      <transition :name="[(this.$route.fullPath !== '/' ? 'scale-slide' : '')]">
        <component :is="Component"/>
      </transition>
    </router-view>
</template>

<script>
import appHeader from "./components/Common/Header";

export default {
  components:{
    appHeader
  },
  data() {
    return {
      active: false
    }
  },
  methods: {
    leave: function (el, done){
      setTimeout(function (){
        this.active = false
      },3000)
    },
    enter: function (el, done) {
      this.active = true
    }
  }
}
</script>
<style scoped>
.transitionHolder{
  display: flex;
  min-height: 150vh;
  width: 100%;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
}
.scale-slide-enter-active,
.scale-slide-leave-active {
  position: absolute;
  transition: all 0.85s ease;
}
.scale-slide-enter-from {
  left: -100%;
}
.scale-slide-enter-to {
  left: 0%;
}
.scale-slide-leave-from {
  transform: scale(1);
}
.scale-slide-leave-to {
  transform: scale(0.8);
}
</style>
